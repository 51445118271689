import axios from 'axios';
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min';
import routes from '../../../public/js/fos_js_routes';

export default class CKEditorUploadAdapter {
  constructor(loader) {
    this.loader = loader;
    routes.port = window.location.port;
    routes.scheme = window.location.protocol.replace(':', '');
    // noinspection JSUnresolvedFunction
    Routing.setRoutingData(routes);
  }

  upload() {
    const formData = new FormData();
    formData.append('picture', this.loader.file);


    return new Promise((resolve, reject) => {
      // noinspection JSUnresolvedFunction
      axios.post(
        Routing.generate('admin_upload_picture'),
        formData,
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      ).then(({ data }) => (resolve(data)))
        .catch(() => reject(new Error('Une erreur est survenue lors du téléchargement de votre image')));
    });
  }

  abort() {
    window.console.log(this.loader.uploadResponse);
  }
}
