/* global toastr */

/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */

import configureCkeditor from './CKEditorConfiguration';
import DatePickerConfig from './DatePickerConfig';

const handleFlashMessage = () => {
  const flashMessage = $('.flash-message');

  // noinspection JSUnresolvedFunction
  flashMessage.each((key, item) => {
    toastr[$(item).data('type')]($(item).data('message'));
  });
};

const handleSideNav = () => {
  const buttonNav = $('.button-collapse');
  const navLinks = $('.menu-item a');


  // noinspection JSUnresolvedFunction
  buttonNav.sideNav();

  navLinks.on('click', e => {
    const arrow = $(e.currentTarget).find('i.rotate-icon');
    if (arrow) {
      // noinspection JSUnresolvedFunction
      arrow.hasClass('fa-angle-down')
        ? arrow.removeClass('fa-angle-down').addClass('fa-angle-up')
        : arrow.removeClass('fa-angle-up').addClass('fa-angle-down');
    }
  });
};

const handleCollapsible = () => {
  const collapsible = $('.collapsible');

  // noinspection JSUnresolvedFunction
  collapsible.collapsible();
};

const handleDelete = () => {
  const btnDeletes = $('a[data-action="delete"]');
  const deleteModal = $('#confirmDeleteModal');
  const btnConfirmDelete = $('#btnConfirmDelete');

  btnDeletes.on('click', event => {
    event.preventDefault();
    deleteModal.modal({
      show: true,
    });
    btnConfirmDelete.attr('href', $(event.currentTarget).data('href'));
  });
};

const handleDatePicker = () => {
  // language=JQuery-CSS
  const datePickers = $('.datepicker');
  const timePickers = $('.timepicker');
  const hiddenDatePickers = $('.hiddendatepicker');
  const hiddenTimePickers = $('.hiddentimepicker');
  const dateTimePickers = $('.datetimepicker');

  const paramDatePicker = DatePickerConfig('FR');
  paramDatePicker.max = $(this).data('max') === 'today';

  // noinspection JSUnresolvedVariable
  if (datePickers.length) {
    // noinspection JSUnresolvedFunction
    datePickers.each((event, item) => {
      // noinspection JSUnresolvedFunction
      $(item).pickadate(paramDatePicker);
      $(item).removeAttr('readonly');
    });
  }

  // noinspection JSUnresolvedVariable
  if (timePickers.length) {
    // noinspection JSUnresolvedFunction
    timePickers.pickatime({
      twelvehour: false,
    });
  }

  // noinspection JSUnresolvedVariable
  if (hiddenDatePickers.length && hiddenTimePickers.length) {
    // noinspection JSUnresolvedFunction
    hiddenDatePickers.each((event, itemDate) => {
      // noinspection JSUnresolvedFunction
      $(itemDate).pickadate({
        container: '.outlet-datetime',
        onStart:   () => {},
        onSet:     item => {
          if ('select' in item) {
            $(this.node[0]).parent().first().find('.hiddentimepicker')
              .first()
              .pickatime('show');
          } else if ('clear' in item) {
            const current = $($(this)[0].$node[0]);
            // noinspection JSUnresolvedFunction
            current.first().parent().prev().val('');
            current.val('');
            current.next().next().val('');
          }
        },
      }).pickadate('picker');
    });

    // noinspection JSUnresolvedFunction
    hiddenTimePickers.each((event, item) => {
      item.pickatime({
        twelvehour: false,
        donetext:   'Valider',
        afterShow:  () => {
          $('<span class="btn-flat clockpicker-button">Retour</span>').on('click', e => {
            e.stopPropagation();
            // noinspection JSUnresolvedFunction
            $(this).pickatime('hide');
            $(this).parent().first().find('.hiddendatepicker')
              .first()
              .pickadate('open');
          }).prependTo($('.datetime-pickercontain').find('.picker__footer'));
        },
        afterHide: () => $('.datetime-pickercontain').find('.picker__footer span').remove(),
        afterDone: () => {
          const hiddenDatePicker = $(this).parent().first().find('.hiddendatepicker')
            .first();
          const hiddenTimePicker = $(this);
          const dateTimePicker = $(this).parent().first().parent()
            .first()
            .find('.datetimepicker')
            .first();
          $(dateTimePicker[0]).val(`${hiddenDatePicker[0].value} - ${hiddenTimePicker[0].value}`);
        },
      });
    });

    // noinspection JSUnresolvedFunction
    dateTimePickers.each(() => {
      // noinspection JSUnresolvedFunction
      const hiddenDatePickerValue = $(this).first().next().find('.hiddendatepicker')
        .first()
        .val();
      // noinspection JSUnresolvedFunction
      const hiddenTimePickerValue = $(this).first().next().find('.hiddentimepicker')
        .first()
        .val();
      // noinspection JSValidateTypes
      if (hiddenDatePickerValue.length !== '' && hiddenTimePickerValue !== '') {
        // noinspection JSUnresolvedFunction
        $(this).first().val(`${hiddenDatePickerValue} - ${hiddenTimePickerValue}`);
      }
      $(this).click(event => {
        event.stopPropagation();
        $(this).parent().find('.hiddendatepicker').first()
          .pickadate('open');
      });
    });
  }
};

// noinspection JSValidateTypes
$(document).ready(() => {
  handleFlashMessage();
  handleSideNav();
  handleCollapsible();
  handleDelete();
  configureCkeditor();
  handleDatePicker();
});
