import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import UploadAdapter from './CKEditorUploadAdapter';

const items = [
  'heading',
  '|',
  'bold',
  'italic',
  'alignment',
  'link',
  'bulletedList',
  'numberedList',
  'imageUpload',
  'blockQuote',
  'insertTable',
  'undo',
  'redo',
];

const toolbar = [
  'link',
  'imageStyle:full',
  'imageStyle:side',
  'imageStyle:alignLeft',
  'imageStyle:alignCenter',
  'imageStyle:alignRight',
  '|',
  'imageTextAlternative',
];

const styles = [
  'full',
  'side',
  'alignLeft',
  'alignCenter',
  'alignRight',
];

export default () => (
  document.querySelectorAll('.ck-editor-container textarea').forEach(element => {
    ClassicEditor
      .create(element, {
        toolbar: {
          items,
        },
        image: {
          toolbar,
          styles,
        },
      })
      .then(editor => {
        // eslint-disable-next-line no-param-reassign
        editor.plugins.get('FileRepository').createUploadAdapter = loader => new UploadAdapter(loader);

        // create an observer instance to update hidden textarea on blur
        const observer = new MutationObserver((() => {
          const formGroup = element.parentNode;
          const textarea = formGroup.querySelector('textarea');

          if (textarea) {
            textarea.value = editor.getData();
          }
        }));

        observer.observe(element, {
          attributes:    true,
          childList:     true,
          characterData: true,
        });
      })
      .catch(error => {
        console.error(error);
      });
  })
);
