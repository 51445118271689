export default lang => {
  switch (lang) {
    case 'FR':
      return {
        monthsFull:    ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthsShort:   ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
        weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        weekdaysFull:  ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        today:         'Aujourd\'hui',
        clear:         'Effacer',
        close:         'Fermer',
        format:        'dd/mm/yyyy',
        formatSubmit:  'dd/mm/yyyy',
        selectYears:   60,
        hiddenSuffix:  '',
      };

    default:
      return {};
  }
};
